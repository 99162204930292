import {
  BatchReconciledResponse,
  paginationDefault,
  ReconciliationData,
  reconciliationDataDefault,
  ReconciliationState,
  ReconciliationWithEmbeddedOrder,
  Step
} from "@/interfaces/retailSalesReconciliation";
import { traceabilityService } from "@/services/traceability.service";
import { TablePagination } from "helix-vue-components";
import cloneDeep from "lodash/cloneDeep";
import { Component, Vue } from "vue-property-decorator";
import Template from "./RetailSalesReconciliation.template.vue";

const TODAY = new Date();

@Component({
  mixins: [Template]
})
export default class RetailSalesReconciliationComponent extends Vue {
  public currentStep: Step = Step.CHOOSE_DAY;
  public selectedDate: Date = TODAY;
  public reconciliation: ReconciliationData = reconciliationDataDefault;
  public pagination: TablePagination = paginationDefault;
  public loading: boolean = false;

  public change(data: ReconciliationState) {
    this.currentStep = data.step;

    if (data.date) {
      this.selectedDate = data.date;
    }
  }

  public updateReconciliationData(data: ReconciliationData) {
    this.reconciliation = cloneDeep(data);
  }

  public updatePagination(pagination: TablePagination) {
    const firstElement = (pagination.currentPage - 1) * pagination.itemsPerPage;
    const lastElement =
      firstElement + pagination.itemsPerPage <= pagination.totalItems
        ? firstElement + pagination.itemsPerPage
        : pagination.totalItems;
    this.pagination = {
      ...pagination,
      from: firstElement + 1,
      to: lastElement
    };
  }

  public async batchReconciled(result: BatchReconciledResponse) {
    this.loading = true;
    const traceDetails = await traceabilityService.getBatchDetails(
      result.batch_uid
    );

    const reconciliation = cloneDeep(this.reconciliation);
    const batch = reconciliation.batches_to_reconcile[result.batch_uid];
    batch.local_batch_information = {
      ...batch.local_batch_information,
      ...traceDetails!.local
    };
    batch.remote_batch_information = {
      ...batch.remote_batch_information,
      ...traceDetails!.remote
    };

    batch.has_errors = !traceDetails!.status.includes("IN_SYNC");

    if (
      !batch.has_errors &&
      reconciliation.summary.cannabis_batches_with_errors > 0
    ) {
      reconciliation.summary.cannabis_batches_with_errors--;
    }

    this.reconciliation = reconciliation;
    this.loading = false;
  }

  public duplicateOrdersReconcilied(data: ReconciliationWithEmbeddedOrder) {
    this.loading = true;
    const reconciliation = cloneDeep(this.reconciliation);
    reconciliation.duplicate_orders = reconciliation.duplicate_orders.filter(
      duplicate =>
        duplicate.order.trc_receipt_id !== data.order.order.trc_receipt_id
    );

    if (reconciliation.summary.total_transactions_to_reconcile > 0) {
      reconciliation.summary.total_transactions_to_reconcile--;
    }
    this.reconciliation = reconciliation;
    this.loading = false;
  }
}
